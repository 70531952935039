
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const exist = (s: String | undefined): Boolean => s !== undefined || (s ?? '').length > 0

export const ScrollToTop: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (window.scrollY !== 0) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      })
    }
  }, [pathname])

  return null
}
