import React, { FC } from 'react'
import classNames from 'classnames'
import scss from './TechBlock.module.scss'
import TechParamsPicBlock from '../components/TechParamsPicBlock'

export interface CardII {
  mainTitle: string
  texts: {[key: string]: string[]} // key-типа заголовок куска статьи, а value - 2 строки текста, которые разобьются на 2 блока
  scrPic: string[]
  techParams: {[key: string]: string}
}
const TechBlock: FC<CardII> = ({ mainTitle, texts, scrPic, techParams }) => {
  return (
    <div className={classNames(scss.TechBlock)}>
      <div className={scss.pattern_techical_review}>
            <div className={scss.title_section}>
                {mainTitle}
            </div>
            <div className={scss.text_section}>
                {
                    Object.keys(texts).map((key, i) => {
                      return (
                            <div className={scss.text_section_block} key={i}>
                                <div className={scss.title_text_section}>
                                    {key}
                                </div>
                                <div className={scss.text_text_section}>
                                    <div className={scss.text_item_text_section}>
                                        {texts[key][0]}
                                    </div>
                                    <div className={scss.text_item_text_section}>
                                    {texts[key][1]}
                                    </div>
                                </div>
                            </div>
                      )
                    })
                }
            </div>
            <TechParamsPicBlock
                scrPic={scrPic}
                techParams={techParams}
            />
        </div>
    </div>

  )
}

export default TechBlock
