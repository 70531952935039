import classNames from 'classnames'
import i18next from 'i18next'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ScrollToTop } from '../tools'
import scss from './BaseLayout.module.scss'

const BaseLayout: FC<{children?: React.ReactNode}> = ({ children }) => {
  const { t } = useTranslation()
  const routesDictionary: { [key: string]: string[] } = {
    '/': ['new1', 'new2', 'new3'],
    'tech/': ['trouble', 'maintenance', 'installing', 'commissioning'],
    'eng/': ['designing', 'development', 'industry'],
    'equipment/': ['accessories', 'vision', 'vocational-training', 'cnc-machines']
  }
  interface NavLinkI {
    name: string
    route: string
    toggle?: () => void
    onMobile?: () => void
  }

  const [openTechDrop, setOpenTechDrop] = useState<boolean>(false)
  const [openProjDrop, setOpenProjDrop] = useState<boolean>(false)
  const [engDropMobile, setEngDropMobile] = useState<boolean>(false)
  const [techDropMobile, setTechDropMobile] = useState<boolean>(false)
  useEffect(() => {
    document.onclick = (e) => {
      if ((e?.target as any)?.id !== 'nav3' && (e?.target as any)?.id !== 'nav2') {
        setOpenTechDrop(false)
        setOpenProjDrop(false)
      }
    }
    window.onscroll = (e) => {
      if (checkBox.current?.checked ?? false) {
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto'
        })
      }
    }

    return () => {
      document.onclick = null
    }
  }, [])

  const headerOptions: NavLinkI[] = [
    {
      name: t('base.home'),
      route: ''
    },
    {
      name: t('base.about'),
      route: 'about/'
    },
    {
      name: t('base.eng'),
      route: 'eng/',
      toggle: () => { setOpenProjDrop(prev => !prev); setOpenTechDrop(false) },
      onMobile: () => { setEngDropMobile(prev => !prev); setTechDropMobile(false) }
    },
    {
      name: t('base.tech'),
      route: 'tech/',
      toggle: () => { setOpenTechDrop(prev => !prev); setOpenProjDrop(false) },
      onMobile: () => { setTechDropMobile(prev => !prev); setEngDropMobile(false) }
    },
    {
      name: t('base.equipment'),
      route: 'equipment/'
    },
    {
      name: t('base.steprobotics'),
      route: 'steprobotics/'
    },
    {
      name: t('base.contact'),
      route: 'contact/'
    }
  ]
  const checkBox = useRef<HTMLInputElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const isThisLocation = (s: string): boolean => {
    return location.pathname.substr(1) === (s) || (routesDictionary[s] ?? []).includes(location.pathname.replace(/\//g, ''))
  }
  return (
      <div className={scss['base-layout']}>
        <ScrollToTop></ScrollToTop>
        <header className={scss['base-layout__header']}>
          <img className={scss['base-layout__logo']} src="/header/logo.svg"/>
          <input className="checkbox" type="checkbox" name="" id="" ref={checkBox}/>
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <div className="menu-items">
              {headerOptions.map((i, k) => <><li style={{ listStyleType: 'none' }} key={k} ><Link onClick={(e: any) => {
                if (i.onMobile != null) {
                  i.onMobile()
                  e.preventDefault()
                }
              }} id={'nav' + String(k)} to={'/' + i.route}>{i.name}</Link></li>
              { k === 2
                ? (engDropMobile
                    ? <div className={scss.drop__mobile}>
                        <div className={scss.panel} onClick={() => { navigate('/designing') }}>{t('base.drop1')}</div>
                        <div className={scss.panel} onClick={() => { navigate('/development') }}>{t('base.drop2')}</div>
                        <div className={scss.panel} onClick={() => { navigate('/industry') }}>{t('base.drop3')}</div>
                      </div>
                    : null)
                : null }
              { k === 3
                ? (techDropMobile
                    ? <div className={scss.drop__mobile} >
                        <div className={scss.panel} onClick={() => { navigate('/trouble') }}>{t('base.drop4')}</div>
                        <div className={scss.panel} onClick={() => { navigate('/maintenance') }}>{t('base.drop5')}</div>
                        <div className={scss.panel} onClick={() => { navigate('/installing') }}>{t('base.drop6')}</div>
                        <div className={scss.panel} onClick={() => { navigate('/commissioning') }}>{t('base.drop7')}</div>
                      </div>
                    : null)
                : null }
              </>) }
          </div>
          <div className={scss.nav}> {
            headerOptions.map((i, k) => <Link id={'nav' + String(k)} to={'/' + i.route} onClick={(e: any) => {
              if (i.toggle != null) {
                i.toggle()
                e.preventDefault()
              }
            }}
            className={classNames(scss.option, isThisLocation(i.route) ? scss.active : '') } key={k}>{i.name}</Link>)
          }</div>
          <select className={scss.langs} onChange={ (e) => { i18next.changeLanguage(e.target.value).catch(() => alert('Unreachable error')) } }>
            <option value="en" selected={i18next.language === 'en'}>English</option>
            <option value="ru" selected={i18next.language === 'ru'}>Русский</option>
          </select>
          { (openTechDrop || openProjDrop)
            ? <div className={scss.drops}>
            <div className={scss.drop} style ={openProjDrop ? { visibility: 'visible' } : {}}>
              <div className={scss.panel} onClick={() => { navigate('/designing') }}>{t('base.drop1')}</div>
              <div className={scss.panel} onClick={() => { navigate('/development') }}>{t('base.drop2')}</div>
              <div className={scss.panel} onClick={() => { navigate('/industry') }}>{t('base.drop3')}</div>
            </div>
            <div className={scss.drop} style ={openTechDrop ? { visibility: 'visible' } : {}}>
              <div className={scss.panel} onClick={() => { navigate('/trouble') }}>{t('base.drop4')}</div>
              <div className={scss.panel} onClick={() => { navigate('/maintenance') }}>{t('base.drop5')}</div>
              <div className={scss.panel} onClick={() => { navigate('/installing') }}>{t('base.drop6')}</div>
              <div className={scss.panel} onClick={() => { navigate('/commissioning') }}>{t('base.drop7')}</div>
            </div>
          </div>
            : null}
        </header>
        {children}
        <footer className={scss['base-layout__header']}>
          <img className={scss['base-layout__logo']} src="/header/logo.svg"/>
          <div className={scss.nav}>
            {
              headerOptions.map((i, k) => <Link id={'nav' + String(k)} to={'/' + i.route} onClick={(e: any) => {
                if (i.toggle != null) {
                  window.scrollTo({
                    behavior: 'smooth',
                    left: 0,
                    top: 0
                  })
                  i.toggle()
                  e.preventDefault()
                }
              }}
              className={classNames(scss.option, isThisLocation(i.route) ? scss.active : '') } key={k}>{i.name}</Link>)
            }
          </div>

        </footer>
        <div className={scss.bottom}>
          <span>{t('pages.main.Ltd')}</span>
          <span>{t('pages.main.MSRN')}</span>
          <span>{t('pages.main.ITN')}</span>
          <span>{t('pages.main.IEC')}</span>
        </div>
      </div>
  )
}

export default BaseLayout
