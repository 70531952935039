import React, { FC } from 'react'
import scss from './ChessCard.module.scss'

const ChessCard: FC<{text: string}> = ({ text }) => {
  return (
        <div className={scss.card_chess}>
            <span>{text}</span>
        </div>
  )
}

export default ChessCard
