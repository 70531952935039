/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, useState } from 'react'
import scss from './Carousel.module.scss'
interface CarouselI {
  titleArray: string[]
  srcName: string
  className?: string
}
const Carousel: FC<CarouselI> = ({ titleArray, srcName, className }) => {
  const [checkedCard, setCheckedCard] = useState<Number>(2)

  return (
    <section id="slider" className={className ?? ''}>

        {titleArray.map((i, k) => (
            <input
                key={i + String(k)} type="radio" name="slider" id={'s' + String(k + 1)}
                checked={checkedCard === k + 1}
                onChange={e => {
                  if (e.currentTarget.checked) {
                    setCheckedCard(k + 1)
                  }
                }}/>))}

        {titleArray.map((i, k) => (
            <label key={i + '!' + String(k)} htmlFor={'s' + String(k + 1)} id={'slide' + String(k + 1)}>
                <img src={srcName + String(k + 1) + '.png'}></img>
                {i.length > 0 ? <div className={scss['card-title']}>{i}</div> : null }
            </label>
        ))}
        <div className={scss.slider_blur}></div>
    </section>

  )
}

export default Carousel
