/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-multiple-empty-lines */
import classNames from 'classnames'
import React, { FC } from 'react'
import scss from './CardCNCMachine.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { useTranslation } from 'react-i18next'
import Table from 'react-bootstrap/Table'

export interface CardI{
  title: string
  srcImgs: string[]
  tableData: {
    header: string[]
    rows: string[][]
  }
}
const CardCNCMachine: FC<CardI> = ({ title, srcImgs, tableData }) => {
  const { t } = useTranslation()
  return (
        <div className={classNames(scss.CardCNCMachine)}>
            <div className={scss.machine_item}>
                <Swiper
                    spaceBetween={30}
                    pagination={{
                    clickable: true
                    }}
                    
                    modules={[Pagination]}
                    className={classNames(scss.machine_pic, 'cnc_card_swiper')}
                >
                    {
                        srcImgs.map((value, index) => {
                          return (
                            <SwiperSlide key={index} style={{ background: 'transparent' }}><img src={value}  /></SwiperSlide>
                          )
                        })
                    }
                </Swiper>
                <div className={scss.machine_tech_info}>
                  <div className={scss.machine_title}>{title}</div>
                    <Table responsive>
                      <thead>
                        <tr>
                          {
                            tableData.header.map((key, index) => {
                              return (
                                    <th style={{ textAlign: index === 0 ? 'left' : 'center' }} className={scss.machine_tech_values_item} key={index}>
                                        <span className={scss.machine_tech_name_value}>{t('pages.steprobotics.' + key, key)}</span>
                                    </th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          tableData.rows.map((row, upIndex) => {
                            const tds = row.map((rowItem, index) => {
                              const text = t('pages.steprobotics.' + rowItem, rowItem)
                              
                              return <td style={{ textAlign: index === 0 ? 'left' : 'center' }} key={index}>
                                {index === 0 ? <b>{text}</b> : text}
                              </td>
                            })

                            return <tr key={upIndex}>
                              {tds}
                            </tr>
                          })
                        }
                      </tbody>
                    </Table>
                </div>
            </div>
        </div>
  )
}

export default CardCNCMachine
