import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import scss from './News1.module.scss'
import style from './Equipment.module.scss'
import Card from '../components/Card'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const Equipment: FC = () => {
  const { t } = useTranslation()
  const cardsData: string[] = [
    'pages.equipment.cards.item1',
    'pages.equipment.cards.item2'
  ]
  const cardLinks: string[] = [
    '/vocational-training',
    '/vision'
  ]
  return (
    <BaseLayout>
    <div className={classNames('container', style.bg)}>

        <h1 className='title'>{t('pages.equipment.title')}</h1>
        <div className={scss.info}>
            <span>{t('pages.equipment.infoText1')}</span>
            <span>{t('pages.equipment.infoText2')}</span>
        </div>
        <h1 className='title'>{t('pages.equipment.title2')}</h1>
        <div className={style.panel}>
            {
                cardsData.map((v, k) => {
                  return (
                    <Card
                      link={cardLinks[k]}
                      hideCommentOnPhone={false}
                      className={style.crd}
                      src={'/equipment/crd' + String(k + 1) + '.png'}
                      comment={t(v)}
                      key={k}
                    />
                  )
                }
                )
            }
        </div>
        <h2 className={style.h2}>{t('pages.equipment.title3')}</h2>
        <img className={style.list} src="/equipment/list.svg" alt="" />
        <img className={style['list-mobile']} src="/equipment/col.svg" alt="" />
    </div>
    </BaseLayout>
  )
}

export default Equipment
