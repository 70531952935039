import React, { FC } from 'react'
import './App.scss'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import News1 from './pages/News1'
import Main from './pages/Main'
import News2 from './pages/News2'
import News3 from './pages/News3'
import About from './pages/About'
import Equipment from './pages/Equipment'
import Trouble from './pages/Trouble'
import Maintenance from './pages/Maintenance'
import Installing from './pages/Installing'
import Accessories from './pages/Accessories'
import Commissioning from './pages/Commissioning'
import Designing from './pages/Designing'
import Industry from './pages/Industry'
import Development from './pages/Development'
import Contact from './pages/Contact'
import Vision from './pages/Vision'
import CNCMachines from './pages/CNCMachines'
import VocationalTraining from './pages/VocationalTraining'
import Steprobotics from './pages/steprobotics'

const App: FC = () => {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="new1" element={<News1 />} />
      <Route path="new2" element={<News2 />} />
      <Route path="new3" element={<News3 />} />
      <Route path="about/" element={<About />} />
      <Route path="equipment/" element={<Equipment />} />
      <Route path="trouble/" element={<Trouble />} />
      <Route path="maintenance/" element={<Maintenance />} />
      <Route path="installing/" element={<Installing />} />
      <Route path="steprobotics/" element={<Steprobotics />} />
      <Route path="commissioning/" element={<Commissioning />} />
      <Route path="designing/" element={<Designing />} />
      <Route path="industry/" element={<Industry />} />
      <Route path="development/" element={<Development />} />
      <Route path="contact/" element={<Contact />} />
      <Route path="accessories/" element={<Accessories />} />
      <Route path="vision/" element={<Vision />} />
      <Route path="vocational-training/" element={<VocationalTraining />} />
      <Route path="cnc-machines/" element={<CNCMachines />} />
    </Routes>
  </Router>
  )
}

export default App
