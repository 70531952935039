// @ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import scss from './Contact.module.scss'

import { useTranslation } from 'react-i18next'

interface ContactsI {
  pos: string
  info: string
}

const Contact: FC = () => {
  const { t } = useTranslation()

  const placementData: ContactsI[] = [
    {
      pos: 'pages.contacts.lbl1',
      info: 'pages.contacts.valueLbl1'
    },
    {
      pos: 'pages.contacts.lbl2',
      info: 'pages.contacts.valueLbl2'
    }
  ]
  const contactsData: ContactsI[] = [
    {
      pos: 'pages.contacts.lbl4',
      info: 'pages.contacts.valueLbl4'
    },
    {
      pos: 'pages.contacts.lbl5',
      info: 'pages.contacts.valueLbl5'
    }

  ]
  return (
    <BaseLayout>
        <div className={classNames('container', scss.contact)} style={{ paddingBottom: '8vh' }}>
            <h1 className='title'>{t('pages.contacts.title')}</h1>
            <span className={scss.data}>
                <div className={scss.data__places}>
                    {placementData.map((value, i) => <div className={scss.data__place} key={i}>
                        <h3>{t(value.pos)}</h3>
                        <span>{t(value.info)}</span>
                    </div>)}
                </div>
                <div className={scss.data__contacts}>
                    {contactsData.map((value, i) => <>
                        <h3>{t(value.pos)}</h3>
                        <span style={{ whiteSpace: 'pre-line' }}>{t(value.info)}</span>
                    </>)}
                </div>
            </span>
        </div>
        <div className={scss.map}>
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac7fb154513d30f91ffd3d9f7f9d4d548119aa1bed11635b1423479eb4af65ff8&amp;source=constructor" width="100%" height="490" frameBorder="0"></iframe>
        </div>
    </BaseLayout>
  )
}

export default Contact
