// @ts-nochec
import React, { FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Pagination } from 'swiper'
import { size, useSize } from '../useSize'

const StepSwiper: FC = () => {
  const slides = []
  for (let i = 0; i < 5; i++) {
    slides.push(<SwiperSlide key={i}><img src={`/main/slide${i + 1}.png`} alt="" /></SwiperSlide>)
  }
  return (
    <>
      <Swiper
        slidesPerView={useSize() !== size.mobile ? 5 : 1}
        spaceBetween={17}
        loop={true}
        pagination={{
          clickable: true,
          renderCustom: function (index, className) {
            // eslint-disable-next-line @typescript-eslint/quotes
            return `<div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span><span class="swiper-pagination-bullet"></span></div>`
          }
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {slides}
      </Swiper>
    </>
  )
}

export default StepSwiper
