import React, { FC } from 'react'
import { exist } from '../tools'
import scss from './ProjectCard.module.scss'
import { useTranslation } from 'react-i18next'

export interface ProjectCardI {
  title: string
  comment: string | string[]
  src?: string
  showButton?: boolean
  titleFontSize?: string
}
const ProjectCard: FC<ProjectCardI> = ({ src = '', title, comment, showButton = true, titleFontSize }) => {
  const { t } = useTranslation()
  return (
        <div className={scss['project-card']}>
            <img src={src} alt="" />
            <div className={scss.text}>
                <div className={scss.text__panel}>

                  <h3 style={exist(titleFontSize) === true ? { fontSize: titleFontSize } : {}}>{ title }</h3>
                  { Array.isArray(comment)
                    ? <div className={scss.comment}>
                        {
                          comment.map((text, key) => <p key={key}>{text}</p>)
                        }
                      </div>
                    : <p>{ comment }</p> }
                </div>
                {showButton
                  ? <div className={scss.watch}>
                    <svg width="46" height="28" viewBox="0 0 46 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23 7C19.0796 7 15.8942 10.1379 15.8942 14C15.8942 17.8621 19.0796 21 23 21C26.9205 21 30.1058 17.8621 30.1058 14C30.1058 10.1379 26.9205 7 23 7ZM22.461 11.9241C21.5789 11.9241 20.8438 12.6483 20.8438 13.5172H18.4915C18.5405 11.3448 20.3047 9.6069 22.461 9.6069V11.9241Z" fill="black"/>
                    <path d="M44.5624 12.5517C42.1611 9.6069 33.5852 0 23 0C12.4149 0 3.83892 9.6069 1.43765 12.5517C0.751577 13.3724 0.751577 14.5793 1.43765 15.4483C3.83892 18.3931 12.4149 28 23 28C33.5852 28 42.1611 18.3931 44.5624 15.4483C45.2485 14.6276 45.2485 13.4207 44.5624 12.5517ZM23 24.1379C17.3154 24.1379 12.7089 19.6 12.7089 14C12.7089 8.4 17.3154 3.86207 23 3.86207C28.6846 3.86207 33.2911 8.4 33.2911 14C33.2911 19.6 28.6846 24.1379 23 24.1379Z" fill="black"/>
                    </svg>

                    <div>{t('pages.projectCard.btnText')}</div>
                </div>
                  : null }
            </div>
        </div>
  )
}

export default ProjectCard
