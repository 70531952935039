/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-trailing-spaces */
import classNames from 'classnames'
import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import scss from './CNCMachines.module.scss'
import CardCNCMachine, { CardI } from '../components/CardCNCMachine'
import { useTranslation } from 'react-i18next'
import OrderForm from '../components/OrderForm'
const paramsCNCCards: CardI[] = [{
  title: 'CK6120/ CK6125',
  srcImgs: ['/cncmachine/1.png', '/cncmachine/1.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CK6120', 'CK6125'],
    rows: [
      [
        'swingoverbed', 'mm', '190', '230'
      ],
      [
        'swingovercrossslide', 'mm', '80', '125'
      ],
      [
        'distancebetweencenters', 'mm', '200', '200'
      ],
      [
        'spindlebore', 'mm', '37', '50'
      ],
      [
        'spindleboretaper', '-', 'MT5', 'MT5'
      ],
      [
        'spindlenosetype', '-', 'A2-4', 'A2-4'
      ],
      [
        'spindlespeedsteps', '-', 'Stepless', 'Stepless'
      ],
      [
        'spindlespeedrange', 'rpm', '150-2500', '150-2500'
      ],
      [
        'turrettoolpost', '-', 'Gang type', 'Gang type'
      ],
      [
        'toolsize', 'mm', '16*16', '16*16'
      ],
      [
        'xaxistravel', 'mm', '300', '300'
      ],
      [
        'zaxistravel', 'mm', '320', '320'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '8000', '8000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '10000', '10000'
      ],
      [
        'tailstockquilldiameter', 'mm', '45', '45'
      ],
      [
        'tailstockquilltaper', '-', 'MT4', 'MT4'
      ],
      [
        'tailstockquilltravel', 'mm', '50', '50'
      ],
      [
        'mainmotorpower', 'kw', '3', '3'
      ],
      [
        'coolantmotorpower', 'kw', '0.09', '0.09'
      ],
      [
        'machineweight', 'kg', '800', '850'
      ],
      [
        'overalldimension', 'mm', '1500*1100*1650', '1500*1100*1650'
      ]
    ]
  }
},
{
  title: 'CK6130/ CK6132/CQK6136',
  srcImgs: ['/cncmachine/2.png', '/cncmachine/2.2.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CK6130', 'CK6132', 'CQK6136'],
    rows: [
      [
        'swingoverbed', 'mm', '300', '320', '360'
      ],
      [
        'swingovercrossslide', 'mm', '100', '110', '150'
      ],
      [
        'distancebetweencenters', 'mm', '500/700', '500/700', '500/700'
      ],
      [
        'spindlebore', 'mm', '60', '60', '60'
      ],
      [
        'spindleboretaper', '-', 'MT6', 'MT6', 'MT6'
      ],
      [
        'spindlenosetype', '-', 'A2-5', 'A2-5', 'A2-5'
      ],
      [
        'spindlespeedsteps', '-', 'Stepless', 'Stepless', 'Stepless'
      ],
      [
        'spindlespeedrange', 'rpm', '100-2000', '100-2000', '100-2000'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '20*20', '20*20', '20*20'
      ],
      [
        'xaxistravel', 'mm', '160', '170', '180'
      ],
      [
        'zaxistravel', 'mm', '500/700', '500/700', '500/700'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '6000', '6000', '6000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '8000', '8000', '8000'
      ],
      [
        'tailstockquilldiameter', 'mm', '50', '50', '50'
      ],
      [
        'tailstockquilltaper', '-', 'MT4', 'MT4', 'MT4'
      ],
      [
        'tailstockquilltravel', 'mm', '90', '90', '90'
      ],
      [
        'mainmotorpower', 'kw', '4', '4', '4'
      ],
      [
        'coolantmotorpower', 'kw', '0.09', '0.09', '0.09'
      ],
      [
        'machineweight', 'kg', '1150', '1200', '1250'
      ],
      [
        'overalldimension', 'mm', '1900*1200*1700', '1900*1200*1700', '1900/2100*1200*1700'
      ]
    ]
  }
},
{
  title: 'CK6136V / CK6140V',
  srcImgs: ['/cncmachine/3.png', '/cncmachine/3.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CK6136V', 'CK6140V'],
    rows: [
      [
        'swingoverbed', 'mm', '360', '400'
      ],
      [
        'swingovercrossslide', 'mm', '180', '240'
      ],
      [
        'distancebetweencenters', 'mm', '500/750', '750/1000'
      ],
      [
        'spindlebore', 'mm', '48', '48 (opt.:82)'
      ],
      [
        'spindleboretaper', '-', 'MT6', 'MT6'
      ],
      [
        'spindlenosetype', '-', 'A2-6', 'A2-6(opt.:A2-8)'
      ],
      [
        'spindlespeedsteps', '-', 'Stepless', 'Stepless'
      ],
      [
        'spindlespeedrange', 'rpm', '150-2000', '150-2000(opt.:150-1800)'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '20*20', '20*20'
      ],
      [
        'xaxistravel', 'mm', '280', '280'
      ],
      [
        'zaxistravel', 'mm', '550/750', '750/1000'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '8000', '8000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '10000', '10000'
      ],
      [
        'tailstockquilldiameter', 'mm', '60', '60'
      ],
      [
        'tailstockquilltaper', '-', 'MT4', 'MT4'
      ],
      [
        'tailstockquilltravel', 'mm', '100', '100'
      ],
      [
        'mainmotorpower', 'kw', '5.5', '5.5 (opt.: 7.5)'
      ],
      [
        'coolantmotorpower', 'kw', '0.09', '0.09'
      ],
      [
        'machineweight', 'kg', '1560-1700', '1800-2000'
      ],
      [
        'overalldimension', 'mm', '2000*1500*1700', '2200*1500*1700'
      ]
    ]
  }
},
{
  title: 'CK6140D / CK6150D',
  srcImgs: ['/cncmachine/4.png', '/cncmachine/4.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CK6140D', 'CK6150D'],
    rows: [
      [
        'swingoverbed', 'mm', '400', '500'
      ],
      [
        'swingovercrossslide', 'mm', '180', '250'
      ],
      [
        'distancebetweencenters', 'mm', '750/1000/1500/2000', '750/1000/1500/2000'
      ],
      [
        'spindlebore', 'mm', '52', '52'
      ],
      [
        'spindleboretaper', '-', 'MT6', 'MT6'
      ],
      [
        'spindlenosetype', '-', 'C6', 'C6'
      ],
      [
        'spindlespeedsteps', '-', 'Manualsteps', 'Manualsteps'
      ],
      [
        'spindlespeedrange', 'rpm', 'L:70-700 , H:160-1600', 'L:70-700 , H:160-1600'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '20*20', '25*25'
      ],
      [
        'xaxistravel', 'mm', '240', '300'
      ],
      [
        'zaxistravel', 'mm', '750/1000/1500/2000', '750/1000/1500/2000'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '6000', '6000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '8000', '8000'
      ],
      [
        'tailstockquilldiameter', 'mm', '65', '75'
      ],
      [
        'tailstockquilltaper', '-', 'MT4', 'MT5'
      ],
      [
        'tailstockquilltravel', 'mm', '140', '140'
      ],
      [
        'mainmotorpower', 'kw', '5.5', '7.5'
      ],
      [
        'coolantmotorpower', 'kw', '0.125', '0.125'
      ],
      [
        'machineweight', 'kg', '1700-2200', '1900-2400'
      ],
      [
        'overalldimension', 'mm', '2200*1600*1700', '3500*1650*170'
      ]
    ]
  }
},
{
  title: 'CK6136 / CK6140 / CK6150/ CK6160 / CK6170',
  srcImgs: ['/cncmachine/5.png', '/cncmachine/5.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CK6136', 'CK6140', 'CK6150', 'CK6160', 'CK6170'],
    rows: [
      [
        'swingoverbed', 'mm', '360', '400', '500', '600', '700'
      ],
      [
        'swingovercrossslide', 'mm', '190', '210', '260', '2000', '440'
      ],
      [
        'distancebetweencenters', 'mm', '750', '1000', '1500', '1500', '2000'
      ],
      [
        'spindlebore', 'mm', '52 (Opt. 80)', '52 (Opt. 80)', '52 (Opt. 80)', '52 (Opt. 80)', '52 (Opt. 80)'
      ],
      [
        'spindleboretaper', '-', 'MT6', 'MT6', 'MT6', 'MT6', 'MT6'
      ],
      [
        'spindlenosetype', '-', 'C6', 'C6', 'C6', 'C6', 'C6'
      ],
      [
        'spindlespeedsteps', '-', 'Manualsteps', 'Manualsteps', 'Manualsteps', 'Manualsteps', 'Manualsteps'
      ],
      [
        'spindlespeedrange', 'rpm', 'L:100-500 H:500-1800', 'L:100-500 H:500-1800', 'L:100-500 H:500-1800', 'L:100-500 H:500-1800', 'L:100-500 H:500-1800'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition', 'Electricposition', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '20*20', '25*25', '25*25', '25*25', '25*25'
      ],
      [
        'xaxistravel', 'mm', '200', '230', '280', '320', '350'
      ],
      [
        'zaxistravel', 'mm', '750', '1000', '1500', '1500', '2000'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '6000', '6000', '6000', '6000', '6000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '8000', '8000', '8000', '8000', '8000'
      ],
      [
        'tailstockquilldiameter', 'mm', '65', '65', '65', '65', '65'
      ],
      [
        'tailstockquilltaper', '-', 'MT4', 'MT4', 'MT5', 'MT5', 'MT5'
      ],
      [
        'tailstockquilltravel', 'mm', '130', '130', '130', '130', '130'
      ],
      [
        'mainmotorpower', 'kw', '4', '4', '5.5', '5.5', '5.5'
      ],
      [
        'coolantmotorpower', 'kw', '0.09', '0.09', '0.09', '0.125', '0.125'
      ],
      [
        'machineweight', 'kg', '1600-1900', '1700-2000', '1800-2100', '1900-2200', '2000'
      ],
      [
        'overalldimension', 'mm', '2200*1600*1700', '2500*1700*1750', '3000*1750*1800', '3500*1750*1800', '3500*1750*1800'
      ]
    ]
  }
},
{
  title: 'CAK6150V / CAK6160V',
  srcImgs: ['/cncmachine/6.png', '/cncmachine/6.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CAK6150V', 'CAK6160V'],
    rows: [
      [
        'swingoverbed', 'mm', '500', '600'
      ],
      [
        'swingovercrossslide', 'mm', '250', '390'
      ],
      [
        'distancebetweencenters', 'mm', '1000/1500/2000', '1000/1500/2000'
      ],
      [
        'spindlebore', 'mm', '82(Opt. 130)', '82(Opt. 130)'
      ],
      [
        'spindleboretaper', '-', 'Metric 90/1:20', 'Metric 90/1:20'
      ],
      [
        'spindlenosetype', '-', 'A2-8(Opt.A2-11)', 'A2-8(Opt.A2-11)'
      ],
      [
        'spindlespeedsteps', '-', 'Stepless', 'Stepless'
      ],
      [
        'spindlespeedrange', 'rpm', '1800 (Opt. 800)', '1800 (Opt. 800)'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '25*25', '25*25'
      ],
      [
        'xaxistravel', 'mm', '300', '300'
      ],
      [
        'zaxistravel', 'mm', '1000/1500/2000', '1000/1500/2000'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '6000', '6000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '10000', '10000'
      ],
      [
        'tailstockquilldiameter', 'mm', '75', '75'
      ],
      [
        'tailstockquilltaper', '-', 'MT5', 'MT5'
      ],
      [
        'tailstockquilltravel', 'mm', '200', '200'
      ],
      [
        'mainmotorpower', 'kw', '7.5', '7.5(Opt. 11)'
      ],
      [
        'coolantmotorpower', 'kw', '0.125', '0.125'
      ],
      [
        'machineweight', 'kg', '2900/3300/3700', '3200/3500/4000'
      ],
      [
        'overalldimension', 'mm', '3650*1700*1800', '4250*1800*1900'
      ]
    ]
  }
},
{
  title: 'CAK6140 / CAK6150 / CAK6161 / CAK6166 / CAK6180',
  srcImgs: ['/cncmachine/7.png', '/cncmachine/7.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CAK6140 CAK6140 B CAK6140 C', 'CAK6150 CAK6150 B CAK6150 C', 'CAK6161 CAK6161 B CAK6161 C', 'CAK6166 CAK6166 B CAK6166 C', 'CAK6180 CAK6180 B CAK6180 C'],
    rows: [
      [
        'swingoverbed', 'mm', '400', '500', '610', '660', '800'
      ],
      [
        'swingovercrossslide', 'mm', '180', '250', '350', '380', '520'
      ],
      [
        'distancebetweencenters', 'mm', '750', '1000', '1500', '2000', '3000'
      ],
      [
        'spindlebore', 'mm', '52 (80 B 105 C)', '52 (80 B 105 C)', '52 (80 B 105 C)', '52 (80 B 105 C)', '52 (80 B 105 C)'
      ],
      [
        'spindleboretaper', '-', 'MT6 (90 1:20  B)', 'MT6 (90 1:20  B)', 'MT6 (90 1:20  B)', 'MT6 (90 1:20  B)', 'MT6 (90 1:20  B)'
      ],
      [
        'spindlenosetype', '-', 'C6 D8 for B & C', 'C6 D8 for B & C', 'C6 D8 for B & C', 'C6 D8 for B & C', 'C6 D8 for B & C'
      ],
      [
        'spindlespeedsteps', '-', 'Manual 3 steps', 'Manual 3 steps', 'Manual 3 steps', 'Manual 3 steps', 'Manual 3 steps'
      ],
      [
        'spindlespeedrange', 'rpm', '21-150', '66-500', '162-1500', '162-1500', '162-1500'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition', 'Electricposition', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '25*25', '25*25', '25*25', '25*25', '25*25'
      ],
      [
        'xaxistravel', 'mm', '200', '250', '310', '330', '400'
      ],
      [
        'zaxistravel', 'mm', '750', '1000', '1500', '2000', '3000'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '6000', '6000', '6000', '6000', '6000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '10000', '10000', '10000', '10000', '10000'
      ],
      [
        'tailstockquilldiameter', 'mm', '75', '75', '75', '75', '75'
      ],
      [
        'tailstockquilltaper', '-', 'MT5', 'MT5', 'MT5', 'MT5', 'MT5'
      ],
      [
        'tailstockquilltravel', 'mm', '150', '150', '150', '150', '150'
      ],
      [
        'mainmotorpower', 'kw', '7.5', '7.5', '7.5', '11', '11'
      ],
      [
        'coolantmotorpower', 'kw', '0.125', '0.125', '0.125', '0.125', '0.125'
      ],
      [
        'machineweight', 'kg', '2450 - 3850', '2500 - 3950', '2550 - 4050', '2650 - 4150', '2750 - 4250'
      ],
      [
        'overalldimension', 'mm', '2550*1650*1700', '2750*1650*1750', '3000*1750*1800', '3250*1700*1800', '3700*1750*1850'
      ]
    ]
  }
}, 
{
  title: 'CK6163E / 6180E / 6180F / 61100F',
  srcImgs: ['/cncmachine/8.png', '/cncmachine/CNC 8.1.png'],
  tableData: {
    header: ['Specifications', 'unit', 'CK6163E HYD.', 'CK6180E HYD', 'CK6180F HYD.', 'CK61100F HYD.'],
    rows: [
      [
        'swingoverbed', 'mm', '630', '800', '800', '1000'
      ],
      [
        'swingovercrossslide', 'mm', '350', '520', '480', '680'
      ],
      [
        'distancebetweencenters', 'mm', '1000 - 6000', '1000 - 6000', '1000 - 6000', '1000 - 6000'
      ],
      [
        'spindlebore', 'mm', '104', '104', '104', '104'
      ],
      [
        'spindleboretaper', '-', 'Metric 120/MT5', 'Metric 120/MT5', 'Metric 120/MT5', 'Metric 120/MT5'
      ],
      [
        'spindlenosetype', '-', 'C11', 'C11', 'C11', 'C11'
      ],
      [
        'spindlespeedsteps', '-', '4 steps', '4 steps', '4 steps', '4 steps'
      ],
      [
        'spindlespeedrange', 'rpm', '17-1000', '17-1000', '17-1000', '17-800'
      ],
      [
        'turrettoolpost', '-', 'Electricposition', 'Electricposition', 'Electricposition', 'Electricposition'
      ],
      [
        'toolsize', 'mm', '32*32', '32*32', '32*32', '40*40'
      ],
      [
        'xaxistravel', 'mm', '320', '420', '420', '520'
      ],
      [
        'zaxistravel', 'mm', '850-5850', '850-5850', '850-5850', '850-5850'
      ],
      [
        'xaxisrapidtraverse', 'mmmin', '4000', '4000', '4000', '4000'
      ],
      [
        'zaxisrapidtraverse', 'mmmin', '6000', '6000', '6000', '6000'
      ],
      [
        'tailstockquilldiameter', 'mm', '100', '100', 'φ120', 'φ120'
      ],
      [
        'tailstockquilltaper', '-', 'MT5', 'MT5', 'MT6', 'MT6'
      ],
      [
        'tailstockquilltravel', 'mm', '250', '250', '250', '250'
      ],
      [
        'mainmotorpower', 'kw', '11', '11', '15', '15'
      ],
      [
        'coolantmotorpower', 'kw', '0.125', '0.125', '0.125', '0.125'
      ],
      [
        'machineweight', 'kg', '3700-7700', '3900-7900', '4800-8800', '5000-9000'
      ],
      [
        'overalldimension', 'mm', '3300-8300*1750*1850', '3300-8300*1850*1900', '3500-8500*1950*1900', '3500-8500*2000*2100'
      ]
    ]
  }
}]

const CNCLathingMachines: FC = () => {
  const { t } = useTranslation()
  return (
        <BaseLayout >
            <div className={classNames('container', scss.CNCMachines)}>
                <h1 className='title'>{t('pages.cncLathingMachines.title')}</h1>
                <div className={scss.collections_machine}>
                  {
                    paramsCNCCards.map((value, index) => {
                      return (
                        <CardCNCMachine
                          key={index}
                          title= {value.title }
                          srcImgs={value.srcImgs }
                          tableData={value.tableData}
                      />
                      )
                    })
                  }
                </div>
            </div>
            <div className={'container ' + scss.bg}>
              <div className={scss.grid}>
              </div>
              <div className='order-form support'>
                  <OrderForm></OrderForm>
                  <div className='support__panel'>
                      <div>
                          <div>
                              <svg width="60" height="60" fill="none" viewBox="0 0 52 60" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M42.4903 38.6265C40.1766 41.8356 36.4286 43.9257 32.2057 43.9257H26.2804C24.2336 43.9257 22.4905 42.6134 21.8305 40.7767C21.0157 40.5313 20.2168 40.2127 19.4371 39.8215C17.7861 38.9931 16.2869 37.8708 14.9672 36.4804C6.40685 38.3796 0 46.0823 0 55.2974V56.5019C0 58.1577 1.32924 59.5 2.96886 59.5H49.0311C50.6708 59.5 52 58.1577 52 56.5019V55.2974C51.9999 48.1787 48.1763 41.9623 42.4903 38.6265Z" fill="#78C1D4"/>
                                  <path d="M11.8118 30.3009C13.0877 30.3009 14.1984 29.5867 14.7737 28.5328C14.7889 28.5745 14.8042 28.616 14.8198 28.6574C14.8243 28.6699 14.8289 28.6823 14.8335 28.6948C16.1833 32.2828 18.8098 35.2906 22.273 36.6048C23.1106 35.2608 24.5918 34.367 26.2806 34.367H32.2058C32.7969 34.367 33.347 34.1986 33.8292 33.9263C34.6391 33.469 35.3674 32.3088 35.7435 31.6939C36.3453 30.7098 36.8116 29.6674 37.2251 28.531C37.4575 28.9571 37.777 29.3278 38.1598 29.6183V31.0855C38.1598 34.4009 35.4889 37.0982 32.2057 37.0982H26.2804C25.1603 37.0982 24.2521 38.0153 24.2521 39.1465C24.2521 40.2778 25.1603 41.1947 26.2804 41.1947H32.2057C37.7256 41.1947 42.2164 36.6597 42.2164 31.0855V29.6183C43.0375 28.9954 43.5687 28.004 43.5687 26.8872V18.3426C43.5687 17.2028 43.0151 16.1943 42.1652 15.5742C41.506 7.15077 34.5078 0.5 25.9999 0.5C17.492 0.5 10.4938 7.15077 9.83467 15.5741C8.98474 16.1942 8.43121 17.2028 8.43121 18.3424V26.8869C8.43121 28.7777 9.95224 30.3009 11.8118 30.3009ZM25.9999 4.59666C32.2981 4.59666 37.4929 9.45715 38.1001 15.6588C37.7015 15.9755 37.375 16.3802 37.15 16.8445C35.2361 11.802 30.9637 8.29131 25.9998 8.29131C20.9289 8.29131 16.725 11.9227 14.8571 16.8244C14.8546 16.8311 14.8522 16.8379 14.8496 16.8447C14.6247 16.3803 14.2981 15.9756 13.8995 15.6589C14.5071 9.45715 19.7018 4.59666 25.9999 4.59666Z" fill="#78C1D4"/>
                              </svg>
                          </div>
                          <h3>{t('pages.accessories.tsup')}</h3>
                      </div>
                      <span>{t('pages.accessories.foot')}</span>
                  </div>
              </div>
             </div>
        </BaseLayout>
  )
}

export default CNCLathingMachines
