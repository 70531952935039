import React, { FC } from 'react'
import scss from './Wheel.module.scss'
import classNames from 'classnames'
interface WheelI {
  children: React.ReactNode[]
  centerNode: React.ReactNode
  gradeGap?: number
  gradeGapUnits?: string
  linesMargin?: string
}
enum WheelLines {
  top,
  bottom
}

const itemsGapsByKey: { [name: string]: string } = {}

const generateWheelLines = (wheelItems: React.ReactNode[], gradeGap: number, gradeGapUnits: string): React.ReactNode[][] => {
  const top: React.ReactNode[] = []
  let bottom: React.ReactNode[] = []
  let i = 0
  top.push(wheelItems[i])
  let j = wheelItems.length
  const k = ~~(~~(wheelItems.length / 2) / 2)
  let currentGap: number = 0
  let isLineChanged: boolean = false
  do {
    const isBottom: boolean = i + 1 > k
    const currentReciever = (isBottom ? bottom : top)
    if (isBottom && !isLineChanged) {
      isLineChanged = true
      currentGap = 0
    } else currentGap += gradeGap
    console.log(wheelItems[i])
    console.log(itemsGapsByKey[(wheelItems[--j] as any).key] = String(currentGap) + gradeGapUnits)
    console.log(itemsGapsByKey[(wheelItems[++i] as any).key] = String(currentGap) + gradeGapUnits)
    currentReciever.unshift(wheelItems[j])
    currentReciever.push(wheelItems[i])
  } while (i < (wheelItems.length - 2) / 2)
  itemsGapsByKey[(wheelItems[i + 1] as any).key] = String(currentGap + gradeGap) + gradeGapUnits
  bottom = bottom.slice(0, bottom.length / 2).reverse().concat([wheelItems[i + 1]]).concat(bottom.slice(bottom.length / 2).reverse())
  return [top, bottom]
}

const Wheel: FC<WheelI> = ({ children, centerNode, gradeGap = 10, gradeGapUnits = 'vw', linesMargin = '' }) => {
  const wheelLinesItems: React.ReactNode[][] = generateWheelLines(children, gradeGap, gradeGapUnits)

  return (
    <div className={scss.wheel}>
        <div className={classNames(scss.wheel__line, scss['wheel__line-top'])} style={{ margin: linesMargin }}>{
            wheelLinesItems[WheelLines.top].map((i, k) => <div className={scss['item-wrapper_line']} style={{ marginTop: itemsGapsByKey[(i as any).key] }} key={k * 78}>{i}</div>)
        }</div>
        { centerNode }
        <div className={classNames(scss.wheel__line, scss['wheel__line-bottom'])} style={{ margin: linesMargin }}>{
            wheelLinesItems[WheelLines.bottom].map((i, k) => <div className={scss['item-wrapper_line']} style={{ marginTop: itemsGapsByKey[(i as any).key] }} key={k * 7}>{i}</div>)
        }</div>
    </div>
  )
}

export default Wheel
