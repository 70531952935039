import classNames from 'classnames'
import React, { CSSProperties, FC } from 'react'
import { exist } from '../tools'
import scss from './Card.module.scss'
import { useNavigate } from 'react-router-dom'

export interface CardI {
  src: string
  titleStyle?: CSSProperties
  title?: string
  date?: string
  comment?: string
  className?: string
  hideCommentOnPhone?: boolean
  link?: string
}
const Card: FC<CardI> = ({ link, src, titleStyle, title, date, comment, className, hideCommentOnPhone = true }) => {
  const navigate = useNavigate()
  const click = exist(link) === true ? () => { navigate(link ?? '') } : () => {}
  return (
        <div className={scss.card + ' ' + (className ?? ' ')} onClick={() => click()} >
            <img className={scss.card__image} src={src} alt="" />
            <div className={scss['card__hover-blue']}></div>
            { (exist(title) === true) || (exist(date) === true)
              ? <div className={scss.card__text}>
                  { exist(title) === true ? < div style={titleStyle} className={scss.card__title}>{ title }</div> : null }
                  { exist(date) === true ? <div className={scss.card__date}>{ date }</div> : null }
              </div>
              : null }

            { exist(comment) === true ? <div className={classNames(scss.card__comment, hideCommentOnPhone ? scss['card__comment-mobile'] : '') } >{ comment }</div> : null }
        </div>
  )
}

export default Card
