import React, { FC } from 'react'
import classNames from 'classnames'
import scss from './TechParamsPicBlock.module.scss'
import { useTranslation } from 'react-i18next'

export interface CardI {
  scrPic: string[]
  techParams: {[key: string]: string}
}
const TechParamsPicBlock: FC<CardI> = ({ scrPic, techParams }) => {
  const { t } = useTranslation()
  return (
      <div className={classNames(scss.TechParamsPicBlock)}>
        <div className={scss.tech_text_picture_block}>
                  <div className={scss.left_grad_bar}></div>
                  <div className={scss.top_grad_bar}></div>
                  <div className={scss.tech_picture} style={scrPic.length === 1 ? { padding: '0 6vw 0 6vw' } : {}}>
                      {
                          scrPic.map((v, i) => {
                            return (<img src={v} key={i} alt=""/>)
                          })
                      }
                  </div>
                  <div className={scss.tech_text}>
                      <div className={scss.tech_text_title}>{t('tparam')}</div>
                      {
                          Object.keys(techParams).map((v, i) => {
                            return (
                              <div className={scss.tech_name_value_block} key={i}>
                                  <div className={scss.tech_name}>{v}</div>
                                  <div className={scss.tech_value}>{techParams[v]}</div>
                              </div>
                            )
                          }
                          )
                      }
                  </div>
          </div>
      </div>
  )
}

export default TechParamsPicBlock
