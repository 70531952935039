import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import OrderForm from '../components/OrderForm'

import { useTranslation } from 'react-i18next'

const Development: FC = () => {
  const { t } = useTranslation()

  return (
    <BaseLayout>
        <div className='container development_page'>
        <h1 className="title title_e_s_page">{t('pages.development.title')}</h1>

        <div className="text_picture_blocks">
            <div className="text_picture_block">
                <span className="text_block">
                    {t('pages.development.imgText1')}
                </span>
                <div className="picture_block">
                    <img src="/development/serv.png" alt="" />
                </div>
            </div>
            <div className="text_picture_block">
                <span className="text_block">
                    {t('pages.development.imgText2')}
                </span>
                <div className="picture_block">
                    <img src="/development/scheme.png" alt="" />
                </div>
            </div>
        </div>

        <div className="panels_block">
            <div className="panels_block_title">{t('pages.development.panel.title')}</div>
            <div className="panels_block_container">
                <div className="panels_block_item">
                    {t('pages.development.panel.item1')}
                </div>
                <div className="panels_block_item panels_block_item_active">
                    {t('pages.development.panel.item2')}
                </div>
                <div className="panels_block_item">
                    {t('pages.development.panel.item3')}
                </div>
                <div className="panels_block_item">
                    {t('pages.development.panel.item4')}
                </div>
                <div className="panels_block_item panels_block_item_active">
                    {t('pages.development.panel.item5')}
                </div>
                <div className="panels_block_item">
                    {t('pages.development.panel.item6')}
                </div>
            </div>
        </div>
        <div className='order-form support'>
                  <OrderForm></OrderForm>
                  <div className='support__panel'>
                      <div>
                          <div>
                              <svg width="60" height="60" fill="none" viewBox="0 0 52 60" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M42.4903 38.6265C40.1766 41.8356 36.4286 43.9257 32.2057 43.9257H26.2804C24.2336 43.9257 22.4905 42.6134 21.8305 40.7767C21.0157 40.5313 20.2168 40.2127 19.4371 39.8215C17.7861 38.9931 16.2869 37.8708 14.9672 36.4804C6.40685 38.3796 0 46.0823 0 55.2974V56.5019C0 58.1577 1.32924 59.5 2.96886 59.5H49.0311C50.6708 59.5 52 58.1577 52 56.5019V55.2974C51.9999 48.1787 48.1763 41.9623 42.4903 38.6265Z" fill="#78C1D4"/>
                                  <path d="M11.8118 30.3009C13.0877 30.3009 14.1984 29.5867 14.7737 28.5328C14.7889 28.5745 14.8042 28.616 14.8198 28.6574C14.8243 28.6699 14.8289 28.6823 14.8335 28.6948C16.1833 32.2828 18.8098 35.2906 22.273 36.6048C23.1106 35.2608 24.5918 34.367 26.2806 34.367H32.2058C32.7969 34.367 33.347 34.1986 33.8292 33.9263C34.6391 33.469 35.3674 32.3088 35.7435 31.6939C36.3453 30.7098 36.8116 29.6674 37.2251 28.531C37.4575 28.9571 37.777 29.3278 38.1598 29.6183V31.0855C38.1598 34.4009 35.4889 37.0982 32.2057 37.0982H26.2804C25.1603 37.0982 24.2521 38.0153 24.2521 39.1465C24.2521 40.2778 25.1603 41.1947 26.2804 41.1947H32.2057C37.7256 41.1947 42.2164 36.6597 42.2164 31.0855V29.6183C43.0375 28.9954 43.5687 28.004 43.5687 26.8872V18.3426C43.5687 17.2028 43.0151 16.1943 42.1652 15.5742C41.506 7.15077 34.5078 0.5 25.9999 0.5C17.492 0.5 10.4938 7.15077 9.83467 15.5741C8.98474 16.1942 8.43121 17.2028 8.43121 18.3424V26.8869C8.43121 28.7777 9.95224 30.3009 11.8118 30.3009ZM25.9999 4.59666C32.2981 4.59666 37.4929 9.45715 38.1001 15.6588C37.7015 15.9755 37.375 16.3802 37.15 16.8445C35.2361 11.802 30.9637 8.29131 25.9998 8.29131C20.9289 8.29131 16.725 11.9227 14.8571 16.8244C14.8546 16.8311 14.8522 16.8379 14.8496 16.8447C14.6247 16.3803 14.2981 15.9756 13.8995 15.6589C14.5071 9.45715 19.7018 4.59666 25.9999 4.59666Z" fill="#78C1D4"/>
                              </svg>
                          </div>
                          <h3>{t('pages.accessories.tsup')}</h3>
                      </div>
                      <span>{t('pages.accessories.foot')}</span>
                  </div>
              </div>
              </div>
    </BaseLayout>
  )
}

export default Development
