import classNames from 'classnames'
import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import OrderForm from '../components/OrderForm'
import Wheel from '../components/Wheel'
import scss from './Vision.module.scss'
import { useTranslation } from 'react-i18next'

const wheelText: string[] = [
  'pages.vision.wheel.wheelItem1',
  'pages.vision.wheel.wheelItem2',
  'pages.vision.wheel.wheelItem3',
  'pages.vision.wheel.wheelItem4',
  'pages.vision.wheel.wheelItem5',
  'pages.vision.wheel.wheelItem6'
]
const Vision: FC = () => {
  const { t } = useTranslation()

  return (
    <BaseLayout >
      <div className={classNames('container', scss.vision)}>
        <h1 className='title'>{t('pages.vision.title')}</h1>
        <div className={scss.info}>
            {t('pages.vision.info')}
        </div>
        <div className={scss.imgs_block}>
            <img src="/vision/pic1_1.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic1_2.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic1_3.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic2_1.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic2_2.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic2_3.png" className={scss.imgs_block_item}/>
        </div>
        <div className={scss.imgs_block_mobile}>
            <img src="/vision/pic1_1.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic2_1.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic1_2.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic1_3.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic2_2.png" className={scss.imgs_block_item}/>
            <img src="/vision/pic2_3.png" className={scss.imgs_block_item}/>
        </div>
        <div className={scss.btns_block}>
            <div className={scss.btns_block_title}>{t('pages.vision.butsMainTitle')}</div>
            <div className={scss.grid_btns_block}>
                <span className={scss.grid_btns_block_item}>{t('pages.vision.butName1')}</span>
                <span className={classNames(scss.grid_btns_block_item, scss.grid_btns_block_item_active)}>{t('pages.vision.butName2')}</span>
                <span className={scss.grid_btns_block_item}>{t('pages.vision.butName3')}</span>
                <span className={scss.grid_btns_block_item}>{t('pages.vision.butName4')}</span>
                <span className={scss.grid_btns_block_item}>{t('pages.vision.butName5')}</span>
            </div>
        </div>
        <div className={scss.wheel}>
            <Wheel
                centerNode={
                    <div className={scss.mid}>
                        <div className={scss.wheel__text}>{t('pages.vision.wheel.wheelItem7')}</div>
                        <img src="/vision/wheel.png" alt="" />
                        <div className={classNames(scss.wheel__text, scss.end)}>{t('pages.vision.wheel.wheelItem8')}</div>
                    </div>}
                gradeGap={6.5}
            >
                {wheelText.map((item, k) => <div key={k} className={scss.wheel__text}>{t(item)}</div>) }
            </Wheel>
          </div>
          <div className='order-form'>
            <OrderForm></OrderForm>
          </div>
      </div>
    </BaseLayout>
  )
}

export default Vision
