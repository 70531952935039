import classnames from 'classnames'

import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import Card from '../components/Card'
import Wheel from '../components/Wheel'
import { wheelCardsText } from './Main'
import scss from './Main.module.scss'
import style from './About.module.scss'
import Loop from '../components/Loop/Loop'
import { useTranslation } from 'react-i18next'

const About: FC = () => {
  const { t } = useTranslation()
  const wellKnos: string[] = [
    'pages.about.listItem1',
    'pages.about.listItem2',
    'pages.about.listItem3',
    'pages.about.listItem4',
    'pages.about.listItem5'
  ]
  const textPanel: string[] = [
    'pages.about.textPanel.item1',
    'pages.about.textPanel.item2',
    'pages.about.textPanel.item3'
  ]
  const strategyTextSteps: string[] = [
    'pages.about.strategy.item1',
    'pages.about.strategy.item2',
    'pages.about.strategy.item3',
    'pages.about.strategy.item4'
  ]
  return (
        <BaseLayout>
            <div className={ 'container ' + scss.main}>
                <h1 className='title'>{t('pages.about.title')}</h1>
                <div className={classnames('text-panel', style.text)}>
                    <span>{t('pages.about.infoText1')}</span>
                    <span>{t('pages.about.infoText2')}</span>
                    <span>{t('pages.about.infoText3')}</span>
                </div>
                <div className={style.pics}>
                    <img src="/about/pic1.png" alt="" />
                    <img src="/about/pic2.png" alt="" />
                </div>
                <div className={style.wlknow}>
                    <h1>{t('pages.about.titleList')}</h1>
                    <div className={style.wlknow__list}>
                        {wellKnos.map((v, k) => <>
                            {k > 0 ? <div className={style.wlknow__separator}></div> : null}
                            <div key={k} className={style.wlknow__card}>
                                <h1>{k + 1}</h1>
                                <span>{t(v)}</span>
                            </div></>)}
                    </div>
                </div>
                <div className={classnames('text-panel', style.text, style.text_trans)}>
                    {
                        textPanel.map((value, i) => <span key={i}>{t(value)}</span>)
                    }
                </div>
                <div className={scss.wheel__loop}>
                    <h1 className='title'>{t('pages.main.vision')}</h1>
                    <Loop show={1} infiniteLoop={false}>
                        {[5, 0, 1].map((item, k) => <Card className={scss.loop__item} src={'/main/wheel' + String(item + 1) + '.png'} key={k + 10} comment={t(wheelCardsText[item])} hideCommentOnPhone={false}></Card>) }
                    </Loop>
                    <div className={scss['loop-center']}>
                        <img src="/main/eye.png" alt="" />
                    </div>
                    <h1 className='title'>{t('pages.main.mission')}</h1>
                    <Loop show={1} infiniteLoop={false}>
                    {[2, 3, 4].map((item, k) => <Card className={scss.loop__item} src={'/main/wheel' + String(item + 1) + '.png'} key={k + 10} comment={t(wheelCardsText[item])} hideCommentOnPhone={false}></Card>) }
                    </Loop>
                </div>
                <div className={scss.wheel}>
                    <Wheel
                        gradeGap={6.5}
                        centerNode={
                        <div className={scss.wheel__center}>
                            <img src="/main/eye.png" alt="" />
                            <div className={classnames(scss['wheel__table-top'], scss.wheel__table) }><h1>{t('pages.main.vision')}</h1></div>
                            <div className={classnames(scss['wheel__table-bottom'], scss.wheel__table) }><h1>{t('pages.main.mission')}</h1></div>
                        </div>
                        }
                    >
                        {wheelCardsText.map((item, k) => <Card src={'/main/wheel' + String(k + 1) + '.png'} key={k} comment={t(item)}></Card>) }
                    </Wheel>
                </div>
                <div className={style.crt}>
                    <h1>{t('pages.about.certificationsTitle')}</h1>
                    <div className={style.crt__panel}>
                        <img src="/about/crt1.png" alt="" />
                        <img src="/about/crt2.png" alt="" />
                        <img src="/about/crt3.png" alt="" />
                    </div>
                </div>
                <h1 className='title' style={{ marginTop: '15vh' }}>{t('pages.about.strategy.title')}</h1>
                <div className={style.steps}>
                    {
                        strategyTextSteps.map((value, i) => {
                          return (
                                <div className={style.step} key={i}>
                                    <img src={'/about/step' + String(i + 1) + '.svg'} alt="" />
                                    <p>{t(value)}</p>
                                </div>
                          )
                        }
                        )
                    }

                </div>
            </div>
        </BaseLayout>
  )
}

export default About
