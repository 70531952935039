
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classnames from 'classnames'
import React, { FC, useState } from 'react'
import BaseLayout from '../components/BaseLayout'
import Card from '../components/Card'
import Carousel from 'react-bootstrap/Carousel'
import IconPanel from '../components/IconPanel'
import Loop from '../components/Loop/Loop'
import StepSwiper from '../components/StepSwiper'
import Wheel from '../components/Wheel'
import scss from './Main.module.scss'
import { useTranslation } from 'react-i18next'
import OrderForm from '../components/OrderForm'

export const wheelCardsText: string[] = [
  'pages.main.wheel1',
  'pages.main.wheel2',
  'pages.main.wheel3',
  'pages.main.wheel4',
  'pages.main.wheel5',
  'pages.main.wheel6'
]
const Main: FC = () => {
  const { t } = useTranslation()
  const [sh, sSh] = useState<boolean>(false)
  const iconsCardText: Array<{
    title: string
    comment: string
  }> = [
    {
      title: t('pages.main.ic1.title'),
      comment: t('pages.main.ic1.comment')
    },
    {
      title: t('pages.main.ic2.title'),
      comment: t('pages.main.ic2.comment')
    },
    {
      title: t('pages.main.ic3.title'),
      comment: t('pages.main.ic3.comment')
    },
    {
      title: t('pages.main.ic4.title'),
      comment: t('pages.main.ic4.comment')
    }
  ]
  return (
        <BaseLayout>
            <div className={ 'container ' + scss.main} style={{ paddingTop: '0' }}>
              <div className={scss.carousel}>
              <Carousel>
                  <Carousel.Item interval={500}>
                    <img
                      className="d-block w-100"
                      src="main/slider1.png"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>{t('pages.main.Slider1')}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item interval={500}>
                    <img
                      className="d-block w-100"
                      src="main/slider2.png"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>{t('pages.main.Slider2')}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item interval={500}>
                    <img
                      className="d-block w-100"
                      src="main/slider3.png"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>{t('pages.main.Slider3')}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className={scss['top-cards-mobile']}>
              {[t('pages.main.eserv'), t('pages.main.tserv'), t('pages.main.eqp')].map(
                (v, k) => <Card titleStyle={{ fontFamily: 'Bebas Neue', fontWeight: 'initial', width: '100%', textAlign: 'center' }} className={scss['top-card']} key={k} src={'/main/cr' + String(k + 1) + '.png'} title={v}></Card>)}
              </div>
              <div className={scss.news}>
                <h1 className={scss.news__title}>{t('pages.main.news')}</h1>
                <div className={scss.news__layout}>
                  <Card link='new3' src="/main/news1.png" title={t('pages.main.new1')}></Card>
                  <div className={scss['news__layout-line']}>
                    <Card link='new2' className={scss.card_cover} src="/main/news2.png" title={t('pages.main.new2')}></Card>
                  </div>
                </div>
              </div>
              <div className={scss.video}>
                <div className={scss.video__desc}>
                  <h1 className={scss.video__title}>
                    {t('pages.main.rts')}
                  </h1>
                  <div className={classnames(scss['video__player-mobile'], scss.video__player)}>
                    <div className={scss.video__button}>
                      <svg width="30" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M43.668 24.7187L0.667969 48.7188L0.667967 0.71875L43.668 24.7187Z" fill="black"/>
                      </svg>
                    </div>
                  </div>
                  <span className={scss.video__note}>
                    {t('pages.main.vinote')}
                  </span>
                </div>
                <div className={scss.video__player} onClick={() => { window.open('https://www.youtube.com/embed/eTOKvFDDjAQ') }}>
                  <div className={scss.video__button}>
                    <svg width="30" height="40" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M43.668 24.7187L0.667969 48.7188L0.667967 0.71875L43.668 24.7187Z" fill="black"/>
                    </svg>
                  </div>
                </div>
              </div>

              <div className={scss.event}>
                <div className={scss.event__title}>
                  <h1 className={scss['event__title-mobile']}>{t('pages.main.techno')}</h1><h1 className={scss['event__title-roboto']}>·</h1><h1 className={scss['event__title-blue']}>5 - 14 {t('month.feb')}</h1>
                </div>
                <div className={scss.event__article}>
                  <span>
                    {t('pages.main.interop')}
                  </span>
                  <span>
                    {t('pages.main.ev1')}
                    <br />
                    {t('pages.main.ev2')}
                    <br />
                    {t('pages.main.ev3')}
                  </span>
                  <span>
                    {t('pages.main.ptolimp')}
                  </span>
                  <h2>{t('pages.main.addnl')}<a>info@idealtechno.ru</a></h2>
                  <div className={scss.swiper}><StepSwiper></StepSwiper></div>
                  <div className={scss.event__button} onClick={() => { window.open('https://www.google.com/url?q=https://docs.google.com/forms/d/e/1FAIpQLSfzwJJAj5-VQ3K8AW759GLB3eprv3oKPRd-nOKAsoG_vjp1ow/viewform?usp%3Dpp_url&sa=D&source=docs&ust=1680866498609240&usg=AOvVaw0EN6w52DjthiO9QIRk4s_x') }}>{t('pages.main.part')}</div>
                </div>
              </div>
              <div style={{ paddingTop: '7vh' }}>
                {sh ? <OrderForm ></OrderForm> : null}
              </div>
            </div>
        </BaseLayout>
  )
}

export default Main
