import { useState, useEffect } from 'react'

export enum size {
  mobile,
  tablet,
  pc,
  none
}

export const useSize = (): size => {
  const [sizeType, setSizeType] = useState<size>(size.none)

  function refreshState (): void {
    setSizeType(window.innerWidth <= 768 ? size.mobile : size.pc)
  }

  useEffect(() => {
    refreshState()
    window.addEventListener('resize', () => { refreshState() })
    return () => {
      window.removeEventListener('resize', () => { refreshState() })
    }
  }, [])

  return sizeType
}
