import React, { FC, useState } from 'react'
import scss from './OrderForm.module.scss'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

interface FormData {
  name: string
  email: string
  tel: string
  info: string
}

const OrderForm: FC = () => {
  const { t } = useTranslation()
  const valueBtn = t('pages.orderForm.buttonText')

  const [formData, setFormData] = useState<FormData>({
    email: '',
    info: '',
    tel: '',
    name: ''
  })
  const [send, setSend] = useState<boolean>(false)

  function sendFormData ({ name, email, tel, info }: FormData): void {
    axios.post('/email.php', {
      name,
      email,
      tel,
      info
    })
      .then(function (response) {
        setSend(true)
        setTimeout(() => setSend(false), 7000)
      })
      .catch(function (error) {
        alert(error)
      })
  }
  return (
    <form className={scss.order} onSubmit={(e) => { e.preventDefault(); sendFormData(formData) }}>
      <div className={scss.title}>
        <h2>{t('pages.orderForm.title')}</h2>
        {send ? <h2 className={scss.green}>{t('pages.orderForm.send')}</h2> : null }
      </div>
        <div className={scss.order__inputs}>
            <input type="text" placeholder={t('pages.orderForm.placeholderName')} required onChange={(e) => {
              setFormData(prev => { prev.name = e.target.value; return prev })
            }}/>
            <input type="email" placeholder={t('pages.orderForm.placeholderMail')} required onChange={(e) => {
              setFormData(prev => { prev.email = e.target.value; return prev })
            }}/>
            <input type="tel" placeholder={t('pages.orderForm.placeholderPhone')} required onChange={(e) => {
              setFormData(prev => { prev.tel = e.target.value; return prev })
            }}/>
            <textarea name="comment" id="" placeholder={t('pages.orderForm.placeholderComment')} onChange={(e) => {
              setFormData(prev => { prev.info = e.target.value; return prev })
            }}></textarea>
        </div>
        <input type="submit" value={valueBtn} />
    </form>
  )
}

export default OrderForm
