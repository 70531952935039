import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BaseLayout from '../components/BaseLayout'
import scss from './News1.module.scss'

const News1: FC = () => {
  const { t } = useTranslation()
  return (
        <BaseLayout>
            <div className={'container ' + scss.news}>

                <h1 className={scss.title}>{t('pages.new1.title')}</h1>
                <div className={scss.info}>
                    <span>{t('pages.new1.info1')}</span>
                    <span>{t('pages.new1.info2')}</span>
                    <span>{t('pages.new1.info3')}</span>
                </div>
                <img src="/big-bruhs.png" alt="" />
            </div>
        </BaseLayout>
  )
}

export default News1
