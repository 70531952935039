import React, { FC, useEffect, useState } from 'react'
// import styles from './Loop.module.scss'
interface LoopI {
  children: any
  show: number
  infiniteLoop: Boolean
}
const Loop: FC<LoopI> = ({ children, show, infiniteLoop }) => {
  const [currentIndex, setCurrentIndex] = useState(infiniteLoop === true ? show : 0)
  const [length, setLength] = useState<number>(children.length)

  const [isRepeating, setIsRepeating] = useState(infiniteLoop === true && children.length > show)
  const [transitionEnabled, setTransitionEnabled] = useState(true)

  const [touchPosition, setTouchPosition] = useState(null)
  const [isMoving, setIsMoving] = useState(false)

  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length)
    setIsRepeating(infiniteLoop === true && children.length > show)
  }, [children, infiniteLoop, show])

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true)
      }
    }
  }, [currentIndex, isRepeating, show, length])

  const next = (): void => {
    if (isMoving) return
    else setIsMoving(prev => !prev)
    if (isRepeating || currentIndex < (length - show)) {
      setCurrentIndex((prevState: number) => prevState + 1)
    }
    setTimeout(() => setIsMoving(false), 300)
  }

  const prev = (): void => {
    if (isMoving) return
    else setIsMoving(prev => !prev)
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex((prevState: number) => prevState - 1)
    }
    setTimeout(() => setIsMoving(false), 300)
  }

  const handleTouchStart = (e: any): void => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e: any): void => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 3) {
      next()
    }

    if (diff < -3) {
      prev()
    }

    setTouchPosition(null)
  }

  const handleTransitionEnd = (): void => {
    if (isRepeating) {
      console.log('curr index', currentIndex)
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const renderExtraPrev = (): any[] => {
    const output = []
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index])
    }
    output.reverse()
    return output
  }

  const renderExtraNext = (): any[] => {
    const output = []
    for (let index = 0; index < show; index++) {
      output.push(children[index])
    }
    return output
  }

  return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                {
                    (isRepeating || currentIndex > 0) &&
                    <svg onClick={prev} className="left-arrow" width="19" height="34" viewBox="0 0 19 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9434 1.86133L2.54984 17.2548L17.9434 32.6484" stroke="black" strokeWidth="2.63889"/>
                    </svg>
                }
                <div
                    className="carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`carousel-content show-${show}`}
                        style={{
                          transform: `translateX(-${currentIndex * (100 / show)}%)`,
                          transition: !transitionEnabled ? 'none' : 'all 250ms linear'
                        }}
                        onTransitionEnd={() => handleTransitionEnd()}
                    >
                        {
                            (length > show && isRepeating) &&
                            renderExtraPrev()
                        }
                        {children}
                        {
                            (length > show && isRepeating) &&
                            renderExtraNext()
                        }
                    </div>
                </div>
                {
                    (isRepeating || currentIndex < (length - show)) &&
                    <svg onClick={next} className="right-arrow" width="19" height="34" viewBox="0 0 19 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11523 1.86133L16.5088 17.2548L1.11523 32.6484" stroke="black" strokeWidth="2.63889"/>
                    </svg>
                }
            </div>
        </div>
  )
}

export default Loop
