import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import scss from './News2.module.scss'
import style from './News3.module.scss'
import news1Styles from './News1.module.scss'
import classNames from 'classnames'
import ChessCard from '../components/ChessCard'
import { useTranslation } from 'react-i18next'

const News3: FC = () => {
  const { t } = useTranslation()
  const butsData: string [] = [
    t('pages.new3.butsData1'),
    t('pages.new3.butsData2'),
    t('pages.new3.butsData3'),
    t('pages.new3.butsData4'),
    t('pages.new3.butsData5')
  ]
  return (
        <BaseLayout>
            <div className={'container ' + scss.news + ' ' + style.bg}>

                <h1 className={scss.title}>IdealTechno fly with Yellowfin robotics</h1>
                <div className={style.grid}>
                    <div className={classNames(scss['news-grid__icon']) }>
                        <img src="/news/logo.svg" alt="" />
                    </div>
                    <div className={classNames(scss['news-grid__icon'])}>
                        <img src="/news/logo3.png" alt="" style={{ width: '12vw' }}/>

                    </div>
                    <div className={classNames(news1Styles.info, scss.info, style.info)} style={{ margin: '0' }}>
                        <span>{t('pages.new3.info')}</span>
                    </div>
                </div>
                <div className={style.cards}>

                    {butsData.map((i, k) => <ChessCard text={i} key={k}></ChessCard>)}
                </div>
                <div className={scss.panel}>
                    <div className={classNames(news1Styles.info, scss.info)}>
                        <span>{t('pages.new3.panel')}</span>
                    </div>
                </div>
            </div>
        </BaseLayout>
  )
}

export default News3
