import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import scss from './News2.module.scss'
import news1Styles from './News1.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
const News2: FC = () => {
  const { t } = useTranslation()
  return (
        <BaseLayout>
            <div className={'container ' + scss.news}>
                <h1 className={scss.title}>{t('pages.new2.title')}</h1>
                <div className={scss['news-grid']}>
                    <div className={scss['news-grid__icons']}>
                        <div className={scss['news-grid__icon']}>
                            <img src="/news/logo.svg" alt="" />
                        </div>
                        <div className={scss['news-grid__icon']}>
                            <img src="/news/logo2.png" alt="" style={{ width: '12vw' }}/>
                        </div>
                    </div>
                    <div className={classNames(news1Styles.info, scss.info)}>
                        <span>{t('pages.new2.info1')}</span>
                        <span>{t('pages.new2.info2')}</span>
                    </div>
                </div>
                <div className={scss.panel}>
                    <img src="/news/car.png" alt="" />
                    <div className={classNames(news1Styles.info, scss.info)}>
                        <span>{t('pages.new2.panel')}</span>
                        <span>{t('pages.new2.service1')}</span>
                        <span>{t('pages.new2.service2')}</span>
                        <span>{t('pages.new2.service3')}</span>
                        <span>{t('pages.new2.service4')}</span>
                        <span>{t('pages.new2.service5')}</span>
                        <span>{t('pages.new2.service6')}</span>
                        <span>{t('pages.new2.service7')}</span>
                    </div>
                </div>
            </div>
        </BaseLayout>
  )
}
export default News2
