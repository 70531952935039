import classNames from 'classnames'
import React, { FC } from 'react'
import BaseLayout from '../components/BaseLayout'
import TechBlock from '../components/TechBlock'
import scss from './VocationalTraining.module.scss'
import { useTranslation } from 'react-i18next'
// Крч там со стилями намудренно, они дублируются в VocationalTraining.scss и в компонентных стилях TechBlock.module.scss и TechParamsPicBlock.module.scss
// Пачему? ну, потому что тут в дизайне из колеи выбивается элемент, где там 2 фотки, потому прям в FC VocationalTraining html'ем прописан он. Хз надо ли это как-то фиксить.

const VocationalTraining: FC = () => {
  const { t } = useTranslation()

  return (
    <BaseLayout >
      <div className={classNames('container', scss.VocationalTraining)}>
        <h1 className='title'>{t('pages.vocationalTraining.title')}</h1>
        <div className={scss.info}>
          {t('pages.vocationalTraining.info1')}
        </div>
        <TechBlock
            mainTitle={t('pages.vocationalTraining.textBlock1.title')}
            texts={
                {
                  [t('pages.vocationalTraining.textBlock1.subtitle1')]: [
                    t('pages.vocationalTraining.textBlock1.text1'),
                    t('pages.vocationalTraining.textBlock1.text2')
                  ],
                  [t('pages.vocationalTraining.textBlock1.subtitle2')]: [
                    t('pages.vocationalTraining.textBlock1.text3'),
                    t('pages.vocationalTraining.textBlock1.text4')
                  ]
                }
            }
            scrPic={['vocationaltraining/pic1.png']}
            techParams={
                {
                  [t('pages.vocationalTraining.cardTechData1.lbl1')]: t('pages.vocationalTraining.cardTechData1.valuelbl1'),
                  [t('pages.vocationalTraining.cardTechData1.lbl2')]: t('pages.vocationalTraining.cardTechData1.valuelbl2'),
                  [t('pages.vocationalTraining.cardTechData1.lbl3')]: t('pages.vocationalTraining.cardTechData1.valuelbl3'),
                  [t('pages.vocationalTraining.cardTechData1.lbl4')]: t('pages.vocationalTraining.cardTechData1.valuelbl4'),
                  [t('pages.vocationalTraining.cardTechData1.lbl5')]: t('pages.vocationalTraining.cardTechData1.valuelbl5')
                }
            }
        />
        <TechBlock
            mainTitle={t('pages.vocationalTraining.textBlock2.title')}
            texts={
                {
                  [t('pages.vocationalTraining.textBlock2.subtitle1')]: [
                    t('pages.vocationalTraining.textBlock2.text1'),
                    t('pages.vocationalTraining.textBlock2.text2')
                  ]
                }
            }
            scrPic={['vocationaltraining/pic1.png']}
            techParams={
                {
                  [t('pages.vocationalTraining.cardTechData2.lbl1')]: t('pages.vocationalTraining.cardTechData2.valuelbl1'),
                  [t('pages.vocationalTraining.cardTechData2.lbl2')]: t('pages.vocationalTraining.cardTechData2.valuelbl2'),
                  [t('pages.vocationalTraining.cardTechData2.lbl3')]: t('pages.vocationalTraining.cardTechData2.valuelbl3'),
                  [t('pages.vocationalTraining.cardTechData2.lbl4')]: t('pages.vocationalTraining.cardTechData2.valuelbl4'),
                  [t('pages.vocationalTraining.cardTechData2.lbl5')]: t('pages.vocationalTraining.cardTechData2.valuelbl5')
                }
            }
        />
        <div className={scss.pattern_techical_review}>
            <div className={scss.title_section}>
              {t('pages.vocationalTraining.textBlock3.title')}
            </div>
            <div className={scss.text_section}>
                <div className={scss.text_section_block}>
                    <div className={scss.title_text_section}>
                      {t('pages.vocationalTraining.textBlock3.subtitle1')}
                    </div>
                    <div className={scss.text_text_section}>
                        <div className={scss.text_item_text_section}>
                          {t('pages.vocationalTraining.textBlock3.text1')}
                        </div>
                        <div className={scss.text_item_text_section}>
                          {t('pages.vocationalTraining.textBlock3.text2')}
                        </div>
                    </div>
                </div>
                <div className={scss.text_section_block}>
                    <div className={scss.title_text_section}>
                      {t('pages.vocationalTraining.textBlock3.subtitle2')}
                    </div>
                    <div className={scss.text_text_section}>
                        <div className={scss.text_item_text_section}>
                          {t('pages.vocationalTraining.textBlock3.text3')}
                        </div>
                        <div className={scss.text_item_text_section}>
                          {t('pages.vocationalTraining.textBlock3.text4')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={scss.two_imgs_block}>
                <img className={scss.two_imgs_block_pic} src="vocationaltraining/pic2.png" alt="" />
                <img className={scss.two_imgs_block_pic} src="vocationaltraining/pic3.png" alt="" />
            </div>
        </div>
        <TechBlock
            mainTitle={t('pages.vocationalTraining.textBlock4.title')}
            texts={
                {
                  [t('pages.vocationalTraining.textBlock4.subtitle1')]: [
                    t('pages.vocationalTraining.textBlock4.text1'),
                    t('pages.vocationalTraining.textBlock4.text2')
                  ],
                  [t('pages.vocationalTraining.textBlock4.subtitle2')]: [
                    t('pages.vocationalTraining.textBlock4.text3'),
                    t('pages.vocationalTraining.textBlock4.text4')
                  ]
                }
            }
            scrPic={['vocationaltraining/pic4.png', 'vocationaltraining/pic5.png']}
            techParams={
                {
                  [t('pages.vocationalTraining.cardTechData4.lbl1')]: t('pages.vocationalTraining.cardTechData4.valuelbl1'),
                  [t('pages.vocationalTraining.cardTechData4.lbl2')]: t('pages.vocationalTraining.cardTechData4.valuelbl2'),
                  [t('pages.vocationalTraining.cardTechData4.lbl3')]: t('pages.vocationalTraining.cardTechData4.valuelbl3'),
                  [t('pages.vocationalTraining.cardTechData4.lbl4')]: t('pages.vocationalTraining.cardTechData4.valuelbl4')
                }
            }
        />
        <TechBlock
            mainTitle={t('pages.vocationalTraining.textBlock5.title')}
            texts={
                {
                  [t('pages.vocationalTraining.textBlock5.subtitle1')]: [
                    t('pages.vocationalTraining.textBlock5.text1'),
                    t('pages.vocationalTraining.textBlock5.text2')
                  ]
                }
            }
            scrPic={['vocationaltraining/pic6.png', 'vocationaltraining/pic7.png']}
            techParams={
                {
                  [t('pages.vocationalTraining.cardTechData5.lbl1')]: t('pages.vocationalTraining.cardTechData5.valuelbl1'),
                  [t('pages.vocationalTraining.cardTechData5.lbl2')]: t('pages.vocationalTraining.cardTechData5.valuelbl2'),
                  [t('pages.vocationalTraining.cardTechData5.lbl3')]: t('pages.vocationalTraining.cardTechData5.valuelbl3'),
                  [t('pages.vocationalTraining.cardTechData5.lbl4')]: t('pages.vocationalTraining.cardTechData5.valuelbl4'),
                  [t('pages.vocationalTraining.cardTechData5.lbl5')]: t('pages.vocationalTraining.cardTechData5.valuelbl5'),
                  [t('pages.vocationalTraining.cardTechData5.lbl6')]: t('pages.vocationalTraining.cardTechData5.valuelbl6')
                }
            }
        />
        <TechBlock
            mainTitle={t('pages.vocationalTraining.textBlock6.title')}
            texts={
                {
                  [t('pages.vocationalTraining.textBlock6.subtitle1')]: [
                    t('pages.vocationalTraining.textBlock6.text1'),
                    t('pages.vocationalTraining.textBlock6.text2')
                  ]
                }
            }
            scrPic={['vocationaltraining/pic8.png']}
            techParams={
                {
                  [t('pages.vocationalTraining.cardTechData6.lbl1')]: t('pages.vocationalTraining.cardTechData6.valuelbl1'),
                  [t('pages.vocationalTraining.cardTechData6.lbl2')]: t('pages.vocationalTraining.cardTechData6.valuelbl2'),
                  [t('pages.vocationalTraining.cardTechData6.lbl3')]: t('pages.vocationalTraining.cardTechData6.valuelbl3'),
                  [t('pages.vocationalTraining.cardTechData6.lbl4')]: t('pages.vocationalTraining.cardTechData6.valuelbl4'),
                  [t('pages.vocationalTraining.cardTechData6.lbl5')]: t('pages.vocationalTraining.cardTechData6.valuelbl5')
                }
            }
        />
      </div>
    </BaseLayout>
  )
}

export default VocationalTraining
